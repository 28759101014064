var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import 'polymer/polymer-element.html';
import 'iron-flex-layout/iron-flex-layout-classes.html';
import 'paper-input/paper-input.html';
import 'paper-button/paper-button.html';
import './page-register.html';
import '../theme.html';
import '../../../widgets/cover-image/cover.image.ts';
import { customElement, property } from 'taktik-polymer-typescript';
let PageRegisterDefault = class PageRegisterDefault extends Polymer.Element {
    constructor() {
        super(...arguments);
        this.username = '';
        this.password = '';
        this.email = '';
        this.errorMessage = '';
        this.localized = {};
        this.isShowPassword = false;
    }
    register() {
        console.log('register clicked');
        // TODO: Send registration request to Ozone
    }
    cancel() {
        location.href = '#/login';
    }
    showPassword() {
        this.set('isShowPassword', !this.isShowPassword);
    }
    showPassIcon(isShowPassword) {
        return !isShowPassword ? 'app:show-password' : 'app:hide-password';
    }
    inputType(isShowPassword) {
        return isShowPassword ? '' : 'password';
    }
};
__decorate([
    property({ type: Object }),
    __metadata("design:type", Object)
], PageRegisterDefault.prototype, "config", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], PageRegisterDefault.prototype, "username", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], PageRegisterDefault.prototype, "password", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], PageRegisterDefault.prototype, "email", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], PageRegisterDefault.prototype, "errorMessage", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", Object)
], PageRegisterDefault.prototype, "localized", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], PageRegisterDefault.prototype, "isShowPassword", void 0);
PageRegisterDefault = __decorate([
    customElement('page-register-default')
], PageRegisterDefault);
