var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import 'polymer/polymer-element.html';
import './cover-image-default.html';
import { customElement, property } from 'taktik-polymer-typescript';
let CoverImage = class CoverImage extends Polymer.Element {
    constructor() {
        super(...arguments);
        this.src = '';
        this.type = 'center';
    }
    getClass(type) {
        return type;
    }
};
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], CoverImage.prototype, "src", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], CoverImage.prototype, "type", void 0);
CoverImage = __decorate([
    customElement('cover-image-default')
], CoverImage);
