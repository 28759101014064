var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import "polymer/polymer-element.html";
import "iron-flex-layout/iron-flex-layout-classes.html";
import "paper-input/paper-input.html";
import "paper-button/paper-button.html";
import "./page-password.html";
import "../theme.html";
import "../../../widgets/cover-image/cover.image.ts";
import { customElement, property } from "taktik-polymer-typescript";
let PagePasswordDefault = class PagePasswordDefault extends Polymer.Element {
    constructor() {
        super(...arguments);
        this.username = '';
        this.msgSent = false;
        this.route = '';
        this.msgConfirmed = 'Lost password requested, an email might have been sent to you.';
    }
    cancel() {
        location.href = "#/login";
    }
    sendResetRequest(e) {
        e.preventDefault();
        // TODO: implement this when endpoint is ready
        //console.log('form submit')
        fetch(this.config.ozoneApi.lostPassword, {
            method: 'post',
            body: 'login_or_email=' + this.username,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            redirect: 'manual'
        })
            .then(resp => {
            this.set('msgSent', true);
        })
            .catch(err => {
            console.log(err);
        });
    }
};
__decorate([
    property({ type: String }),
    __metadata("design:type", Object)
], PagePasswordDefault.prototype, "username", void 0);
__decorate([
    property({ type: Object }),
    __metadata("design:type", Object)
], PagePasswordDefault.prototype, "config", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Object)
], PagePasswordDefault.prototype, "msgSent", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", Object)
], PagePasswordDefault.prototype, "route", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", Object)
], PagePasswordDefault.prototype, "msgConfirmed", void 0);
PagePasswordDefault = __decorate([
    customElement("page-password-default")
], PagePasswordDefault);
