var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import 'polymer/polymer-element.html';
import 'iron-flex-layout/iron-flex-layout-classes.html';
import './page-reset.html';
import '../theme.html';
import '../../../widgets/cover-image/cover.image.ts';
import { customElement, property } from 'taktik-polymer-typescript';
let PageReset = class PageReset extends Polymer.Element {
    constructor() {
        super(...arguments);
        this.account = '';
        this.password = '';
        this.password2 = '';
    }
    resetPassword() {
        // TODO: implement this when Ozone endpoints are ready
    }
    cancel() {
        location.href = "#/login";
    }
};
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], PageReset.prototype, "account", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], PageReset.prototype, "password", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], PageReset.prototype, "password2", void 0);
PageReset = __decorate([
    customElement('page-reset-proximus')
], PageReset);
