var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import 'polymer/polymer-element.html';
import 'iron-flex-layout/iron-flex-layout-classes.html';
import './page-password.html';
import '../theme.html';
import '../../../widgets/cover-image/cover.image.ts';
import { customElement, property, observe } from 'taktik-polymer-typescript';
let PagePasswordProximus = class PagePasswordProximus extends Polymer.Element {
    constructor() {
        super(...arguments);
        this.username = "";
        this.config = "";
        this.msgSent = false;
        this.route = '';
        this.msgConfirmed = 'Lost password requested, an email might have been sent to you.';
    }
    cancel() {
        location.href = "#/login";
    }
    sendResetRequest(e) {
        e.preventDefault();
        fetch(this.config.ozoneApi.lostPassword, {
            method: 'post',
            body: 'login_or_email=' + this.username,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            redirect: 'manual'
        })
            .then(resp => {
            this.set('msgSent', true);
        })
            .catch(err => {
            console.log(err);
        });
        // const form: HTMLFormElement = document.createElement('form')
        // const input: HTMLInputElement = document.createElement('input')
        // input.setAttribute('name', 'login_or_email')
        // input.setAttribute('value', this.username)
        // form.setAttribute('method', 'post')
        // form.setAttribute('action', this.config.ozoneApi.lostPassword)
        // form.setAttribute('hidden', 'true')
        // form.appendChild(input)
        // document.body.appendChild(form)
        // console.log(form)
        // form.submit()
    }
    onRouteChange() {
        this.set('msgSent', false);
        setTimeout(() => {
            this.$.username.focus();
        }, 500);
    }
};
__decorate([
    property(),
    __metadata("design:type", String)
], PagePasswordProximus.prototype, "username", void 0);
__decorate([
    property(),
    __metadata("design:type", Object)
], PagePasswordProximus.prototype, "config", void 0);
__decorate([
    property(),
    __metadata("design:type", Boolean)
], PagePasswordProximus.prototype, "msgSent", void 0);
__decorate([
    property(),
    __metadata("design:type", String)
], PagePasswordProximus.prototype, "route", void 0);
__decorate([
    property(),
    __metadata("design:type", String)
], PagePasswordProximus.prototype, "msgConfirmed", void 0);
__decorate([
    observe('route'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PagePasswordProximus.prototype, "onRouteChange", null);
PagePasswordProximus = __decorate([
    customElement('page-password-proximus')
], PagePasswordProximus);
